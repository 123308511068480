import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import SEO from 'components/SEO/SEO';
import { Font } from 'components/Text/Text';
import { black, white } from 'shared/colors';
import { ArticleObject } from 'types/shared';
import { BREAKPOINTS } from 'shared/constants';
import { convertToVW } from 'shared/utils';
import { Text } from 'components/Text/Text';

interface Props {
  data: {
    contentfulEditorialPage: {
      articles: ArticleObject[];
    };
  };
  location: {
    pathname: string;
  };
}

const EditorialPage = ({ data, location: { pathname } }: Props) => {
  const articleArray = data.contentfulEditorialPage.articles;

  return (
    <>
      <SEO title="Editorial" pathname={pathname} />
      <div className={css(styles.container)}>
        {articleArray.map(
          ({ id, name, subheader, ctaText, slug, mainImage: { fluid } }) => {
            return (
              <Fragment key={id}>
                <div className={css(styles.flexContainer)}>
                  <Img
                    className={css(styles.bgImage)}
                    alt="Article BG"
                    fluid={fluid}
                  />
                  <div className={css(styles.textWrapper)}>
                    <p className={css(styles.subheader)}>{subheader}</p>
                    <h1 className={css(styles.title)}>{name}</h1>
                    <div className={css(styles.buttonWrapper)}>
                      <Link to={`/editorial/${slug}`}>
                        <button className={css(styles.button)}>
                          <p className={css(styles.buttonText)}>{ctaText}</p>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          }
        )}
      </div>
    </>
  );
};

export default EditorialPage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: black,
    color: white,
    paddingTop: '120px',
    [BREAKPOINTS.TABLET]: {},
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '90px',
    },
  },
  flexContainer: {
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    filter: 'brightness(0.5)',
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapper: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  button: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
    ':focus': {
      border: '1px solid white',
      backgroundColor: white,
    },
    ':hover': {
      border: '1px solid black',
      backgroundColor: black,
      transition: '0.3s ease-in-out',
    },
    ':hover > p': {
      color: white,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
});

export const query = graphql`
  query EditorialPageQuery {
    contentfulEditorialPage {
      articles {
        id
        slug
        name
        subheader
        ctaText
        mainImage {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
